import React, { useState } from "react";
import "./modalcomponent.css";
import Modal from "react-modal";
import { PiXCircleBold } from "react-icons/pi";

const ModalComponent = ({ isOpen, closeModal }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submit
    closeModal();
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className="modal-container"
      >
        <button onClick={closeModal} className="modal-icon">
          <PiXCircleBold size={16} />
        </button>

        <form onSubmit={handleSubmit} className="modal-form">
          <div className="modal-top">
            <label>
              Nome:
              <br />
              <input
                type="text"
                name="name"
                placeholder="Digite seu nome"
                required
              />
            </label>

            <br />

            <label>
              Email
              <br />
              <input
                type="email"
                name="email"
                placeholder="Digite seu email"
                required
              />
            </label>
          </div>

          <br />

          <div className="modal-ratio">
            <label
              className={selectedOption === "diretor" ? "selected" : ""}
              required
            >
              <input
                type="radio"
                name="radioOption"
                value="diretor"
                onChange={handleOptionChange}
              />
              Diretor
            </label>

            <br />

            <label className={selectedOption === "professor" ? "selected" : ""}>
              <input
                type="radio"
                name="radioOption"
                value="professor"
                onChange={handleOptionChange}
              />
              Professor
            </label>

            <br />

            <label
              className={selectedOption === "coordenador" ? "selected" : ""}
            >
              <input
                type="radio"
                name="radioOption"
                value="coordenador"
                onChange={handleOptionChange}
              />
              Coordenador
            </label>

            <br />

            <label className={selectedOption === "outros" ? "selected" : ""}>
              <input
                type="radio"
                name="radioOption"
                value="outros"
                onChange={handleOptionChange}
              />
              Outros
            </label>
          </div>

          <br />

          <button type="submit" className="form-btn">
            <a
              href="https://canguru-de-matematica.s3.amazonaws.com/Manual_de_inscricoes_2024.pdf"
              target="blank"
            >
              Enviar
            </a>
          </button>
        </form>

        <br />

        <div className="form-txt">
          <small>
            Ao clicar em "Enviar", você concorda em permitir que a gente
            armazene e processe as informações enviadas acima para fornecer o
            conteúdo. Você pode receber essas comunicações quando quiser.
          </small>
        </div>
      </Modal>
    </div>
  );
};

export default ModalComponent;
