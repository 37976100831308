import React from "react";
import "./cronograma.css";
import ONLINEMB from "../../assets/img/cronograma/online-mobile.svg";
import ONLINEWEB from "../../assets/img/cronograma/online-web.svg";
import PRESENCIALMB from "../../assets/img/cronograma/presencial-mobile.svg";
import PRESENCIALWEB from "../../assets/img/cronograma/presencial-web.svg";

const Cronograma = () => {
  return (
    <div className="container cronograma-container">
      <div className="cronograma-header">
        <p>
          <a href="/">Home</a> <font color="#141414">/</font> Cronograma
        </p>
      </div>
      <div className="cronograma-content">
        <div className="cronograma-title">
          <p>Concurso Canguru</p>
          <h5>Cronograma</h5>
        </div>
        <div className="cronograma-mobile">
          <div className="presencial-mobile">
            <p>Presencial</p>
            <img src={PRESENCIALMB} alt="" />
          </div>
          <div className="online-mobile">
            <p>Online</p>
            <img src={ONLINEMB} alt="" />
          </div>
        </div>
        <div className="cronograma-web">
          <div className="presencial-web">
            <p>Presencial</p>
            <img src={PRESENCIALWEB} alt="" />
          </div>
          <div className="online-web">
            <p>Online</p>
            <img src={ONLINEWEB} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cronograma;
