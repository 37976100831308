import React, { useState, useEffect } from "react";
import "./banner.css";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";

import CANGURUEMENINO from "../../assets/img/banner/canguru-e-menino.svg";
import CANGURUEMENINA from "../../assets/img/banner/canguru-e-menina.svg";
import MOCKUP from "../../assets/img/banner/mockup-celular.svg";

const Banner = () => {
  const banners = ["banner1", "banner2", "banner3"];
  const [currentBanner, setCurrentBanner] = useState(0);

  const nextBanner = () => {
    setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
  };

  const prevBanner = () => {
    setCurrentBanner(
      (prevBanner) => (prevBanner - 1 + banners.length) % banners.length
    );
  };

  useEffect(() => {
    const timer = setInterval(nextBanner, 5000);
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [currentBanner]);

  return (
    <div id="banner">
      {currentBanner === 0 && (
        <div className="container banner-container banner1">
          <div className="slide-btn1" onClick={prevBanner}>
            <PiCaretLeftBold size={20} />
          </div>
          <div className="banner-txt1">
            <h2>
              16<span>ª</span> edição do Concurso Internacional Canguru de
              Matemática Brasil
            </h2>
            <p>Matemática Divertida, Diferente e Desafiadora</p>
            <small>
              Para todos os estudantes do 3º ano do Fundamental I até a 3ª série
              do Ensino Médio.
            </small>
            <small id="negrito">Provas: março de 2024</small>

            <div className="btn banner-btn1">
              <p>Inscrições Encerradas</p>
            </div>
          </div>

          <div className="banner-img1">
            <img src={CANGURUEMENINO} alt="Canguru e menino com mochila" />
          </div>

          <div className="slide-btn2" onClick={nextBanner}>
            <PiCaretRightBold size={20} />
          </div>
        </div>
      )}

      {currentBanner === 1 && (
        <div className="container banner-container banner2">
          <div className="slide-btn1" onClick={prevBanner}>
            <PiCaretLeftBold size={20} />
          </div>
          <div className="banner-txt2">
            <h2>Seja um Patrocinador</h2>
            <p>CANGURU DE MATEMÁTICA</p>
            <small>
              Inspire a paixão pela matemática! Participe do Patrocínio do
              Concurso Canguru de Matemática 2024 para escolas públicas e ajude
              no engajamento de milhares de alunos.
            </small>

            <div className="btn banner-btn2">
              <a
                href="https://2024.cangurudematematicabrasil.com.br/quero-apoiar"
                target="blank"
              >
                Quero Apoiar
              </a>
            </div>
          </div>

          <div className="banner-img2">
            <img src={CANGURUEMENINA} alt="Canguru e menina" />
          </div>

          <div className="slide-btn2" onClick={nextBanner}>
            <PiCaretRightBold size={20} />
          </div>
        </div>
      )}

      {currentBanner === 2 && (
        <div className="container banner-container banner3">
          <div className="slide-btn1" onClick={prevBanner}>
            <PiCaretLeftBold size={20} />
          </div>

          <div className="banner-txt3">
            <div className="ribbon">
              <p>Atenção estudantes e escolas!</p>
            </div>
            <h2>
              Prepare-se para o concurso do
              <br />
              Canguru de Matemática 2024!
            </h2>
            <small>
              Após a escola realizar as inscrições, todos seus estudantes
              poderão ter acesso a um <b>Quiz Preparatório</b>, vídeos e outros
              materiais exclusivos disponíveis na área do estudante.
            </small>
            <p>Não deixe para a última hora, cadastre seus estudantes agora!</p>
          </div>

          <div className="banner-img3">
            <img src={MOCKUP} alt="Celulares mostrando o produto" />
          </div>

          <div className="slide-btn2" onClick={nextBanner}>
            <PiCaretRightBold size={20} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
