import React from "react";
import "./infos.css";
import { PiHouseLine, PiStudent } from "react-icons/pi";
import BRASIL from "../../assets/img/infos/brasil.svg";

const Infos = () => {
  return (
    <section id="infos">
      <div className="container infos-container">
        <div className="info-card" id="estudantes">
          <div className="info-icon" id="icon-estudantes">
            <PiStudent size={70} />
          </div>
          <div className="info-txt">
            <p>+ de</p>
            <h5>938 MIL</h5>
            <p>estudantes em 2023</p>
          </div>
        </div>
        <div className="info-card" id="escolas">
          <div className="info-icon" id="icon-escolas">
            <PiHouseLine size={70} />
          </div>
          <div className="info-txt">
            <p>+ de</p>
            <h5>4 MIL</h5>
            <p>escolas</p>
          </div>
        </div>
        <div className="info-card" id="estados">
          <div className="info-icon" id="icon-estados">
            <img src={BRASIL} alt="Mapa do Brasil" />
          </div>
          <div className="info-txt">
            <p>Em</p>
            <h5>TODOS OS ESTADOS</h5>
            <p>do Brasil</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Infos;
