import React from "react";
import "./sobre.css";
import CANGURUEMENINA from "../../assets/img/sobre/canguru-e-menina.svg";

const Sobre = () => {
  return (
    <section id="sobre">
      <div className="container sobre-container">
        <div className="sobre-img">
          <img src={CANGURUEMENINA} alt="Canguru e menina sorrindo" />
        </div>

        <div className="sobre-txt">
          <p>Sobre</p>
          <h4>O concurso</h4>
          <small>
            O Concurso Canguru é aberto a todos os estudantes do 3º ano do Fundamental I até a 3ª série do Ensino Médio
            que desejam se dedicar e desafiar seus conhecimentos Matemáticos.
            <br />
            <br />
            São apresentadas questões de raciocínio lógico e resolução de problemas que buscam encorajar o engajamento e
            despertar o amor pela Matemática!
          </small>
        </div>
      </div>
    </section>
  );
};

export default Sobre;
