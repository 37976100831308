import React from "react";
import "./reg-presencial.css";

const RegPresencial = () => {
  return (
    <div className="regulamento-presencial-txt">
      <ul id="regulamento-presencial">
        <li id="participacao">
          <p>1. Participação.</p>
          <small>
            1.1 Podem participar do Concurso Canguru de Matemática Brasil todos
            os alunos de escolas públicas e privadas brasileiras, matriculados
            regularmente do 3º ano do Ensino Fundamental até a 3ª série do
            Ensino Médio, por meio de suas escolas, responsáveis por realizar a
            inscrição para o Concurso.
          </small>
          <br />
          <br />
          <small>
            1.2 É vedada a participação de alunos de escolas preparatórias para
            vestibulares e de cursos livres. A participação é restrita aos
            alunos matriculados em escolas de educação básica devidamente
            cadastradas e inscritas no MEC/INEP.
          </small>
          <br />
          <br />
          <small>
            1.3 Os participantes do Concurso serão divididos em 6 (seis) níveis
            de prova, conforme discriminado no item 5 deste regulamento.
          </small>
          <br />
        </li>
        <br />
        <br />
        <li id="objetivos">
          <p>2. Objetivos.</p>
          <small>
            2.1. São objetivos do Concurso Canguru de Matemática Brasil:
          </small>
          <ul id="list">
            <li>
              <small>
                - Ampliar e incentivar a aquisição dos conhecimentos
                matemáticos.
              </small>
              <br />
            </li>
            <li>
              <small>
                - Contribuir para a melhoria do ensino e aprendizagem da
                Matemática desde o Ensino Fundamental até o Ensino Médio.
              </small>
              <br />
            </li>
            <li>
              <small>
                - Favorecer o estudo de maneira interessante e contextualizada,
                aproximando os alunos do universo da Matemática.
              </small>
              <br />
            </li>
            <li>
              <small>
                - Promover nos alunos a realização e a satisfação através da
                atividade intelectual.
              </small>
              <br />
            </li>
          </ul>
        </li>
        <br />
        <br />
        <li id="inscricao">
          <p>3. Inscrição.</p>
          <small>
            3.1 A inscrição é feita pela escola, que utilizará seu código do
            MEC/INEP de 8 dígitos para identificação durante todo o processo. No
            caso de escolas com mais de uma unidade escolar, cada unidade da
            rede ou do grupo de escolas deve ser inscrita com o seu próprio
            código MEC/INEP.
          </small>
          <br />
          <br />
          <small>
            3.2 As escolas privadas conveniadas com a rede pública de ensino que
            tiverem composição mista (alunos da rede pública e privada) serão
            consideradas como redes privadas em todas as etapas do processo.
          </small>
          <br />
          <br />
          <small>
            3.3 Não são permitidas inscrições de pessoas físicas. Isto é, um
            estudante (ou um grupo de estudantes), pais e familiares não podem
            fazer inscrições para o Concurso sem a intermediação das escolas.
          </small>
          <br />
          <br />
          <small>
            3.4 O período de inscrições e pagamentos será estritamente
            considerado o período disponível no Calendário Oficial do Concurso.
            Não serão aceitas inscrições nem pagamentos fora desse período sob
            qualquer hipótese.
          </small>
          <br />
          <br />
          <small>
            3.5 Durante o período de inscrições, o Responsável de cada escola
            deve inserir os dados solicitados na seção “Inscrições” do site e
            seguir as instruções para o cadastro e o pagamento da taxa de
            inscrição.
          </small>
          <br />
          <br />
          <small>
            3.6 Não serão aceitas inscrições enviadas por meio de mensagens
            eletrônicas, fax, telefone ou postal.
          </small>
          <br />
          <br />
          <small>
            3.7 A inscrição será efetivada após o pagamento da taxa por parte de
            cada escola, que terá o valor definido de acordo com o pacote
            escolhido (Padrão, Personalizado e Concurso + Experience). O
            detalhamento de cada um dos pacotes e seus valores encontram-se no{" "}
            <b>ANEXO I</b>.
          </small>
          <br />
          <br />
          <small>
            3.7.1 Os valores são por escola, independentemente do número de
            alunos participantes do Concurso.
          </small>
          <br />
          <br />
          <small>
            3.7.2 Cada unidade escolar (identificada pelo código MEC/INEP)
            deverá efetuar sua inscrição e o pagamento correspondente. As
            escolas com mais de uma unidade escolar (grupos e redes de escolas)
            deverão realizar o pagamento por código MEC/INEP e não por grupo ou
            rede, conforme previsto no item 3.1.
          </small>
          <br />
          <br />
          <small>
            3.8 O pagamento da taxa de inscrição poderá ser efetuado por meio de
            cartão de crédito ou boleto bancário. No caso do cartão de crédito,
            o pagamento da taxa de inscrição poderá ser realizado à vista sem
            juros ou em até 10x sujeitas a juros. No caso do boleto bancário, o
            pagamento poderá ser efetuado em qualquer agência bancária, casa
            lotérica ou agência dos Correios, seguindo os critérios
            estabelecidos nesses correspondentes bancários. O prazo de
            vencimento do boleto é determinado automaticamente pela plataforma
            do Concurso e não poderá sofrer alterações. No entanto, escolas
            inscritas no Concurso que precisarem de um novo boleto com uma nova
            data de vencimento poderão solicitá-lo, desde que esteja vigente o
            período de inscrições para o Concurso.
          </small>
          <br />
          <br />
          <small>
            3.9 A inscrição da escola só será confirmada mediante a confirmação
            do pagamento dentro do prazo estabelecido. No caso do boleto
            bancário, após o processamento pelo sistema bancário e a confirmação
            do pagamento do boleto dentro do prazo estabelecido.
          </small>
          <br />
          <br />
          <small>
            3.10 Não serão aceitas inscrições de escolas cujo pagamento da taxa
            tenha sido efetuado fora do prazo. No caso do pagamento em cartão, o
            prazo coincide com o fim das inscrições. No caso do pagamento em
            boleto, o prazo é determinado no próprio boleto.
          </small>
          <br />
          <br />
          <small>
            3.11 O Canguru de Matemática Brasil não se responsabilizará por
            solicitações de inscrição não recebidas por motivos de ordem técnica
            dos computadores, falhas de comunicação, congestionamento das linhas
            de comunicação, erros ou atrasos dos bancos ou entidades conveniadas
            no que se refere ao processamento da taxa de inscrição, bem como
            outros fatores que impossibilitem a transferência de dados.{" "}
            <b>Favor atentar ao prazo regulamentar de inscrições</b>.
          </small>
          <br />
          <br />
          <small>
            3.12 As Notas Fiscais de Serviços referentes aos pagamentos das
            inscrições só serão disponibilizadas, no mínimo, 15 dias após a
            confirmação de pagamento pelo banco. Não emitimos, em hipótese
            alguma, Notas Fiscais prévias à confirmação do pagamento pela nossa
            instituição bancária.
          </small>
          <br />
          <br />
          <small>
            3.13 A escola, ao inscrever-se no Concurso Canguru de Matemática
            Brasil, concorda integralmente com todas as condições e regras
            previstas no presente regulamento.
          </small>
          <br />
          <br />
          <small>
            3.14 O valor referente à taxa de inscrição da escola somente será
            reembolsado apenas em caso de cancelamento do Concurso pelo Canguru
            de Matemática Brasil.
          </small>
          <br />
          <br />
          <small>
            3.15 Durante a inscrição da escola no Concurso, o(s) Responsável(is)
            pela aplicação do Canguru na escola poderá(ão) cadastrar login(s) e
            senha(s) para acesso à área reservada. Após a confirmação do
            pagamento da taxa de inscrição, essas informações serão enviadas
            para cada e-mail cadastrado durante a inscrição, possibilitando o
            acesso à área reservada. Somente com esse login e essa senha poderão
            ser acessadas as páginas contendo as provas e folhas de respostas
            para posterior reprodução.
          </small>
        </li>
        <br />
        <br />
        <li id="cadastramento">
          <p>4. Cadastramento completo da escola e dos alunos.</p>
          <small>
            4.1 Após a confirmação do pagamento da inscrição, o Responsável pela
            escola receberá no e-mail fornecido durante a inscrição, a
            confirmação do seu login e da sua senha para acesso à área reservada
            da plataforma. A partir deste momento, ele será considerado, para
            todos os efeitos deste regulamento, o Responsável pelo Concurso
            Canguru na Escola. Juntamente com o login e a senha, o Responsável
            pelo Canguru na Escola receberá instruções para acessar a área
            reservada da plataforma, onde encontrará Manuais de Instruções com
            orientações detalhadas para cada uma das etapas do Concurso.
          </small>
          <br />
          <br />
          <small>
            4.2 Com o login e senha para o aceso à área reservada, o Responsável
            poderá cadastrar os alunos que realizarão a prova.
          </small>
          <br />
          <br />
          <small>
            4.3 O cadastramento dos alunos poderá ser realizado ou alterado
            conforme prazo previsto no Calendário Oficial.
          </small>
        </li>
        <li id="provas">
          <p>5. Provas</p>
          <small>
            5.1 As questões das provas são objetivas (testes) com cinco
            alternativas cada, sendo apenas uma alternativa correta por questão.
          </small>
          <br />
          <br />
          <small>
            5.2 Há seis níveis de provas:
            <br />
            <b>Nível P (Pre Ecolier)</b> – alunos do 3º e 4º anos do EFI.
            <br />
            <b>Nível E (Ecolier)</b> – alunos do 5º e 6º anos do EFI e EFII,
            respectivamente.
            <br />
            <b>Nível B (Benjamin)</b> – alunos do 7º e 8º anos do EFII.
            <br />
            <b>Nível C (Cadet)</b> – alunos do 9º ano do EFII.
            <br />
            <b>Nível J (Junior)</b> – alunos da 1ª e 2ª séries do EM.
            <br />
            <b>Nível S (Student)</b> – alunos da 3ª série do EM.
          </small>
          <br />
          <br />
          <small>
            5.3 Números de questões: as provas dos níveis P e E têm 24 questões
            cada e as dos demais níveis, 30 questões cada.
          </small>
          <br />
          <br />
          <small>
            5.4 Duração máxima: <b>1h 40 min</b> para todos os níveis.
          </small>
          <br />
          <br />
          <small>
            5.5 Estrutura das provas:
            <br />
            As questões são propostas em graus níveis de dificuldade crescente -
            fácil, médio e difícil. Nas provas dos níveis P e E, as questões de
            1 a 8 são de grau fácil, as de 9 a 16 são de grau médio e as de 17 a
            24 são de grau difícil. Nos demais níveis, as questões de 1 a 10 são
            de grau fácil, as de 11 a 20 são de grau médio e as de 21 a 30 são
            de grau difícil. Nos níveis mais elementares (P, E, B, C) predominam
            as habilidades de raciocínio, enquanto nos níveis J e S é exigido
            algum conhecimento técnico.
          </small>
          <br />
          <br />
          <small>
            5.6 Pontuação:
            <br />
            - Nos níveis P e E, as questões de 1 a 8 valem 3 pontos cada, as
            questões de 9 a 16 valem 4 pontos cada e as questões de 17 a 24
            valem 5 pontos cada, totalizando 96 pontos nas 24 questões.
            <br />- Nos demais níveis, as questões de 1 a 10 valem 3 pontos
            cada, as questões de 11 a 20 valem 4 pontos cada e as questões de 21
            a 30 valem 5 pontos cada, totalizando 120 pontos nas 30 questões.
          </small>
          <br />
          <br />
          <small>
            5.6.1 Respostas erradas, rasuradas ou marcadas com mais de uma
            alternativa são penalizadas. É possível evitar a penalização,
            conforme instrução descrita no item 5.7. Para o cálculo final da
            nota, somam-se pontos para eliminar notas negativas, conforme item
            5.7 a seguir.
          </small>
          <br />
          <br />
          <small>
            5.7 Cálculo da pontuação final:
            <br />
            - Respostas erradas, rasuradas ou marcadas com mais de uma
            alternativa por questão na folha de respostas anulam a questão, além
            de acarretar um desconto de 25% do valor da questão.
            <br />
            - Questões não respondidas valem 0 (zero) ponto. O aluno pode deixar
            a questão em branco, se não tiver certeza da resposta.
            <br />- O total de pontos é igual à soma dos pontos obtidos nas
            questões das provas de acordo com as regras acima, mais 24 pontos
            (níveis P e E) ou 30 pontos (demais níveis).
          </small>
          <br />
          <br />
          <small>
            5.8 Conteúdo Programático:
            <br />-{" "}
            <a
              href="/conteudo-programatico"
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Clique aqui
            </a>{" "}
            e acesse o conteúdo programático completo.
          </small>
        </li>
        <br />
        <br />
        <li id="aplicacao-prova">
          <p>6. Recepção e aplicação da prova</p>
          <small>
            6.1 As provas do Concurso Canguru de Matemática Brasil são
            individuais e cada aluno deverá resolver as questões e assinalar a
            alternativa correspondente em seu gabarito. As provas serão
            realizadas na própria escola, que será a responsável por sua
            aplicação de acordo com as instruções disponibilizadas no site e nos
            Manuais disponíveis na área reservada, que o Responsável pelo
            Concurso Canguru na Escola deve acessar usando login e senha.
          </small>
          <br />
          <br />
          <small>
            6.1.1 Qualquer irregularidade verificada durante a aplicação da
            prova, na transcrição ou no envio dos resultados poderá ser punida
            com a exclusão da escola desta edição do Concurso, além da
            impossibilidade de participação nas próximas edições por tempo
            indeterminado.
          </small>
          <br />
          <br />
          <small>
            6.2 As provas estarão disponíveis para as escolas na área reservada
            do site, conforme a data prevista no Calendário Oficial. As provas
            deverão ser baixadas e reproduzidas de acordo com o número de
            inscritos da escola. As folhas de respostas com instruções também
            devem ser reproduzidas, uma para cada aluno que irá fazer a prova,
            de acordo com o seu nível (P, E, B, C, J ou S). Em caso de alunos
            neuro atípicos ou com necessidades especiais, autorizamos as escolas
            a fazerem as adaptações necessárias para a correta aplicação das
            provas.
          </small>
          <br />
          <br />
          <small>
            6.2.1 Pede-se ao Responsável pelo Concurso Canguru na Escola que
            mantenha as provas em sigilo até a data de sua aplicação.
          </small>
          <br />
          <br />
          <small>
            6.3 As provas do Concurso Canguru de Matemática Brasil serão
            aplicadas conforme Calendário Oficial, em horário que convenha à
            escola. Além do período oficial de aplicação, serão divulgadas duas
            datas alternativas, caso a escola não possa realizar as provas na
            data oficial. A escola deverá escolher uma dessas datas alternativas
            e realizar todas as provas no mesmo dia. É expressamente proibida
            pela Associação Internacional a realização da prova <b>ANTES</b> da
            data oficial de aplicação.
          </small>
          <br />
          <br />
          <small>
            6.4 Os cadernos de questões devem ser recolhidos após a aplicação
            das provas e armazenados pela escola. Após a autorização do Comitê
            do Concurso e o decurso do prazo previsto na cláusula 6.6 abaixo,
            este material poderá ser devolvido aos alunos e trabalhado em sala
            de aula.
          </small>
          <br />
          <br />
          <small>
            6.5 Os gabaritos (alternativas corretas) serão divulgados somente
            depois de encerrado o prazo de envio das respostas pelas escolas.
          </small>
          <br />
          <br />
          <small>
            6.6 É expressamente proibida a divulgação das provas e de quaisquer
            de suas questões pela escola fora do âmbito escolar (via internet ou
            outros meios) até 60 dias após a data oficial de aplicação. Essa
            regra faz parte do acordo internacional da AKSF (Associação Canguru
            Sem Fronteiras), pois há países que irão aplicar a prova após o dia
            oficial. A violação dessa regra pode causar o desligamento do país
            onde o fato ocorrer. Solicitamos especial cuidado com as fotos
            realizadas no dia de aplicação para que as questões não fiquem
            visíveis e legíveis.
          </small>
        </li>
        <br />
        <br />
        <li id="envio-respostas">
          <p>7. Envio das respostas dos alunos e correção das provas</p>
          <small>
            7.1 Após a aplicação das provas, é de responsabilidade da escola o
            envio via plataforma das respostas dadas pelos seus alunos, no prazo
            previsto pelo Calendário Oficial. Neste momento, deverão ser
            inseridos eventuais alunos que ainda não constem no sistema bem como
            suas respostas. Após esta inserção, aconselha-se uma verificação
            cautelosa da listagem de alunos para verificar se não há alunos
            faltando.
          </small>
          <br />
          <br />
          <small>
            7.2 O envio das respostas será feito por meio de planilha Excel,
            conforme modelo disponibilizado no site, ou de acordo com as
            instruções disponíveis em Manual próprio. As escolas também podem
            utilizar o aplicativo do Canguru para a captura das respostas dos
            alunos na folha de respostas. Na área reservada, há um manual com
            orientações para download e uso do aplicativo.
          </small>
          <br />
          <br />
          <small>
            7.3 O Responsável pelo Canguru na escola deverá acessar o site do
            Canguru, onde encontrará as instruções para o envio das respostas.
          </small>
          <br />
          <br />
          <small>
            7.4 Por motivos organizacionais, não será permitida a retificação de
            dados após a data limite para envio das respostas. Solicita-se aos
            Responsáveis a revisão dos mesmos antes da data limite. Depois
            disto, não será possível fazer modificações.
          </small>
          <br />
          <br />
          <small>
            7.5 Os dados enviados serão utilizados para a análise dos resultados
            em âmbito nacional, mas não serão divulgados publicamente.
          </small>
        </li>
        <br />
        <br />
        <li id="resultados">
          <p>8. Resultados e premiações</p>
          <small>
            8.1 A plataforma do Concurso Canguru de Matemática Brasil irá fazer
            a correção das respostas enviadas e fornecerá, às escolas
            participantes, os resultados preliminares de seus respectivos alunos
            divididos nas categorias <b>alunos com potencial de premiação</b> e{" "}
            <b>alunos participantes</b>. Os alunos com potencial de premiação
            serão divididos nas quatro categorias abaixo:
            <br />- 1% dos melhores colocados, por nível, em território
            nacional, receberão a classificação <b>ouro</b>;
            <br />- 2% dos melhores colocados, por nível, em território
            nacional, receberão a classificação <b>prata</b>
            ;
            <br />- 3% dos melhores colocados, por nível, em território
            nacional, receberão a classificação <b>bronze</b>, e;
            <br />- 4% dos melhores colocados, por nível, em território
            nacional, receberão a classificação <b>honra ao mérito</b>;
          </small>
          <br />
          <br />
          <small>
            8.1.1 Recomendamos que estes resultados preliminares não sejam
            divulgados aos alunos e aos familiares, pois podem sofrer alterações
            após as retificações necessárias.
          </small>
          <br />
          <br />
          <small>
            8.2 Escolas com alunos com potencial de premiação receberão um aviso
            para que enviem à organização do Canguru as imagens escaneadas das
            folhas de respostas originais desses alunos para a devida
            autenticação, caso ainda não o tenham feito.
          </small>
          <br />
          <br />
          <small>
            <b>Atenção!</b> O envio das folhas de respostas dos alunos com
            potencial de premiação será realizado através de formulário online
            específico. o qual disponibilizaremos na área reservada do site do
            Canguru. Este formulário deverá ser preenchido e entregue dentro das
            datas previstas no Calendário Oficial. O não envio das folhas de
            respostas dos alunos com possibilidade de premiação impedirá que a
            escola adquira medalhas para seus alunos premiados.
          </small>
          <br />
          <br />
          <small>
            8.3 Após a divulgação dos resultados preliminares, a escola terá o
            prazo de 10 dias corridos para apresentação de recursos em relação
            aos resultados preliminares, retificação de nomes, verificação de
            inclusão de todos os alunos e demais informações que a escola houver
            inserido na plataforma Canguru durante o Concurso. Após o decurso
            deste prazo, não será mais possível a retificação dos dados nem a
            inserção de alunos em nosso sistema. Os alunos que não forem
            inseridos não serão considerados participantes desta edição do
            Concurso e não poderão receber certificados.
          </small>
          <br />
          <br />
          <small>
            8.4 A Organização do Concurso Canguru de Matemática Brasil divulgará
            somente para as escolas os resultados finais, contendo os nomes dos
            alunos premiados e as notas de todos os alunos participantes,
            conforme Calendário Oficial. As escolas terão acesso apenas aos
            resultados de seus próprios alunos.
          </small>
          <br />
          <br />
          <small>
            8.4.1 Caberá às escolas divulgarem os resultados para seus alunos e
            familiares da maneira que entenderem conveniente e de acordo com o
            seu calendário de atividades.
          </small>
          <br />
          <br />
          <small>
            8.5 Não haverá ranking de escolas. Cada escola terá acesso apenas
            aos resultados de seus alunos. Serão divulgadas apenas as notas de
            corte das premiações e dados estatísticos gerais ou relativos aos
            alunos da escola, com maiores detalhes, de acordo com o pacote
            selecionado (<b>ANEXO I</b>).
          </small>
        </li>
        <br />
        <br />
        <li id="medalhas">
          <p>9. Medalhas e certificados</p>
          <small>
            9.1 Após a divulgação dos resultados finais, de acordo com o
            Calendário Oficial disponível no site oficial do Concurso Canguru de
            Matemática Brasil, as escolas que desejarem poderão realizar a
            compra das medalhas de premiação para seus alunos. A compra de
            medalhas deverá ser realizada exclusivamente pela escola e não
            poderá ser realizada por alunos e/ou familiares. O Representante do
            Canguru na escola deverá acessar a área reservada do site e realizar
            o seu pedido. Neste momento, ele deverá preencher os dados da escola
            e os dados de envio do pacote de medalhas.
          </small>
          <br />
          <br />
          <small>
            9.2 A escola é responsável por informar corretamente todos os dados
            de endereço para envio e contatos de telefone no ato do pedido. A
            organização do Concurso Canguru de Matemática Brasil não se
            responsabilizará pela não entrega das medalhas em caso de endereços
            e informações erradas ou incompletas.
          </small>
          <br />
          <br />
          <small>
            9.3 Só será permitida a compra do pacote total de medalhas de
            premiação (ouro, prata, bronze e honra ao mérito).
          </small>
          <br />
          <br />
          <small>
            9.4 O pagamento do pedido de medalhas deverá ser realizado por meio
            de cartão de crédito ou boleto bancário. No caso do pagamento via
            boleto, ele poderá ser efetuado em qualquer agência bancária, casa
            lotérica ou agência dos Correios, obedecendo aos critérios
            estabelecidos nesses correspondentes bancários. O prazo de
            vencimento do boleto é dado automaticamente pela plataforma do
            Concurso e não poderá sofrer alterações.
          </small>
          <br />
          <br />
          <small>
            9.5 O pedido de medalhas só será confirmado após a confirmação do
            pagamento. No caso do boleto bancário, após o processamento pelo
            sistema bancário e a confirmação do pagamento dentro do prazo
            estabelecido no próprio boleto.
          </small>
          <br />
          <br />
          <small>
            9.6 O prazo para envio das medalhas dependerá da data em que foi
            feito o pagamento. Os envios acontecem em lotes pré-estabelecidos e
            as datas estarão disponíveis e visíveis na tela do site no momento
            de realização do pedido.
          </small>
          <br />
          <br />
          <small>
            9.7 A Nota Fiscal de Produto (DANFE) será emitida após a confirmação
            de pagamento do pedido e será enviada junto com o pacote de
            medalhas. Após a data de envio do pacote, as Notas Fiscais também
            serão enviadas para o(s) e-mail(s) da escola cadastrado(s) durante a
            inscrição no Concurso Canguru. Não será possível a emissão de DANFE
            antes da confirmação do pagamento pela instituição bancária.
          </small>
          <br />
          <br />
          <small>
            9.8 Caso a escola tenha optado pelo pacote de inscrição no modelo
            Pacote Personalizado (<b>ANEXO I</b>), os itens adquiridos
            (publicações e placas de participação) serão enviados juntamente com
            o pacote de medalhas.
          </small>
          <br />
          <br />
          <small>
            9.9 As escolas participantes terão acesso aos Certificados de
            Participação e de Premiação de todos os alunos que realizaram a
            prova do Concurso. É de responsabilidade da escola a impressão e
            entrega dos Certificados aos seus alunos e familiares.
          </small>
          <br />
          <br />
          <small>
            9.10 Os certificados e medalhas são obrigatoriamente emitidos ou
            produzidos pela organização do Concurso Canguru de Matemática
            Brasil. Qualquer emissão ou produção não oficial desses itens será
            penalizada com a exclusão da participação da escola (principal e
            coligadas, se for o caso) no Concurso do Canguru de Matemática
            Brasil, além de outras medidas judiciais cabíveis.
          </small>
          <br />
          <br />
          <small>
            9.11 O início do período da compra de medalhas é informado no
            Calendário Oficial do Concurso Canguru e o fim do período será
            comunicado via e-mail para o(s) e-mail(s) cadastrado(s) durante a
            inscrição no Concurso.
          </small>
        </li>
        <br />
        <br />
        <li id="regras">
          <p>
            10. Declaração sobre as regras e normas de participação e
            responsabilidades
          </p>
          <small>
            10.1 É imprescindível que o(s) responsável(is) pela organização do
            Canguru em cada escola leia(m) e cumpra(m) rigorosamente as
            cláusulas desse regulamento, em cada estágio da participação. A
            Organização do Concurso Canguru de Matemática Brasil não fará
            qualquer concessão contrária as essas regras, quaisquer que sejam as
            alegações dos solicitantes.
          </small>
        </li>
        <br />
        <br />
        <li id="direitos-autorais">
          <p>11. Direitos autorais</p>
          <small>
            11.1 São reservados à Associação Canguru sem Fronteiras (
            <i>AKSF - Kangourou Sans Frontières</i>) o uso da marca{" "}
            <i>Kangourou des Mathemátiques</i> na França, ou o equivalente em
            outros países, como Canguru de Matemática Brasil, os conteúdos
            produzidos, tais como provas, livros, livretos, produtos
            educacionais, certificados e medalhas.
          </small>
          <br />
          <br />
          <small>
            11.2 As questões das provas podem ser utilizadas dentro do âmbito
            escolar, desde que acompanhadas da indicação da fonte: Canguru de
            Matemática Brasil. No entanto, não podem ser utilizadas para fins
            comerciais, como inclusão em livros e apostilas comercializados por
            editoras ou sistemas didáticos.
          </small>
        </li>
        <br />
        <br />
        <li>
          <p>ANEXO I</p>
          <p>MODELOS DE INSCRIÇÃO E VALORES</p>
          <br />
          <p>PACOTE PADRÃO</p>
          <ul id="list">
            <li>
              &bull;
              <small>
                Acesso <b>online</b> ao cartaz de divulgação do Concurso com
                configurações para impressão.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Download de provas e de folhas de respostas (as folhas de
                respostas serão identificadas com o nome da escola).
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Inserção das respostas dos alunos pelo preenchimento de
                formulário via planilha Excel.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Acesso aos resultados de todos os alunos que realizaram a prova.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Possibilidade de compra de medalhas para os alunos premiados
                (ouro, prata, bronze e honra ao mérito).
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Acesso aos certificados <b>online</b> de premiação e
                participação para todos os alunos que realizaram as provas.
                Estes certificados contêm o nome dos alunos e sua classificação,
                podendo ser impressos pela escola.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Acesso aos certificados <b>online</b> de participação para os
                Coordenadores Canguru cadastrados pela escola.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                <b>Sumário executivo</b>, personalizado, contendo dados sobre o
                desempenho da escola no Concurso,{" "}
                <b>
                  por nível de prova, nível de dificuldade e eixo de conteúdo.
                  Neste sumário, serão analisados os resultados da escola por
                  nível de prova, indicando porcentagem de acerto de questões
                  fáceis, médias e difíceis, e indicando o nível de acerto por
                  eixo (álgebra, geometria, números e lógica). Os resultados
                  serão analisados sem estabelecer comparações com outras
                  escolas.
                </b>{" "}
                Com a participação da escola nas próximas edições do Concurso
                Canguru, será possível estabelecer uma série histórica
                identificando o percurso da escola no Concurso. Para acessar um
                modelo de sumário,
                <a
                  href="https://drive.google.com/file/d/1f9CLPiMhnuWDC8rgGaooYQTaHAawRbrU/view"
                  target="blank"
                >
                  clique aqui
                </a>
                .
              </small>
            </li>
            <br />
            <li>
              <p>
                VALOR: R$ 99,00 para escolas públicas e R$ 198,00 para escolas
                particulares.
              </p>
            </li>
          </ul>
          <br />
          <br />
          <p>PACOTE PERSONALIZADO</p>
          <p>PADRÃO MAIS</p>
          <ul id="list">
            <li>
              &bull;
              <small>
                1 placa de participação da escola no Concurso de 2024.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                1 publicação Canguru, em formato revista, impressa e colorida,
                contendo as questões de 2024 com suas respectivas resoluções
                comentadas.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                <b>Relatório de desempenho individual dos alunos</b> que
                realizaram a prova onde será analisado o índice de acerto das
                questões fáceis, médias e difíceis do aluno, o índice de
                acerto/erro por eixo (álgebra, geometria, números e lógica) e
                sua posição relativa aos demais alunos de seu nível de prova, em
                sua escola (posicionamento em quartil).{" "}
                <b>
                  <a href="https://drive.google.com/file/d/1qlmPc9tfKY0HpsUitW97BzxrnSMvtCQp/view">
                    Clique aqui
                  </a>
                </b>{" "}
                para acessar o modelo.
              </small>
              <br />
            </li>
            <br />
            <li>
              <p>
                VALOR: R$ 275,00 para escolas públicas e R$ 550,00 para escolas
                particulares.
              </p>
            </li>
          </ul>
          <br />
          <br />
          <p>CONCURSO + CANGURU EXPERIENCE:</p>
          <ul id="list">
            <li>
              &bull;
              <small>
                Canguru com você o <b>ano inteiro</b>!
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                <b>Planos de Aula exclusivos</b> do Canguru.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                <b>Atividades de Sondagem</b> para uso em sala de aula.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                <b>Vídeos de formação continuada</b> sobre temas da Matemática
                para Professores.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                <b>Trilhas formativas</b> com diferentes conteúdos e materiais
                do Canguru.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Saiba mais{" "}
                <b>
                  <a href="/">clicando aqui.</a>
                </b>
              </small>
              <br />
            </li>
            <br />
            <li>
              <p>
                VALOR: R$ 699,00 para escolas públicas e R$ 1398,00 para escolas
                particulares.
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default RegPresencial;
