import React, { useState } from "react";
import "./inscricoes.css";
import { PiCheckFatFill } from "react-icons/pi";
import LINHAPERSONALIZADO from "../../assets/img/inscricoes/linha-personalizado.svg";
import BG from "../../assets/img/inscricoes/bg.svg";

const Tab = ({ label, activeTab, onClick }) => {
  const handleClick = () => {
    onClick(label);
  };

  return (
    <div
      className={`tab ${activeTab === label ? "active" : ""}`}
      onClick={handleClick}
    >
      {label}
    </div>
  );
};

// --- VALORES COM DESCONTO ---
const TabContent = ({ activeTab }) => {
  return (
    <div className="tab-content">
      <div className="cards-inscricoes">
        <div className="card-padrao">
          <div className="semi-circle-padrao"></div>
          <div className="card-padrao-content">
            <div className="padrao-top-txt">
              <h4>
                Concurso <font color="#231f20">Canguru</font>
              </h4>
              <small>Padrão</small>
            </div>
            <div className="padrao-mid-txt">
              <div className="padrao-item1">
                <PiCheckFatFill size={14} color="#009ddc" />
                <p>
                  Participação no Concurso
                  <br />
                  conforme Regulamento
                </p>
              </div>
              <div className="padrao-item2">
                <PiCheckFatFill size={14} color="#009ddc" />
                <p>
                  Certificação digitais para
                  <br />
                  estudantes e professores
                </p>
              </div>
              <div className="padrao-item3">
                <div className="item3-top">
                  <PiCheckFatFill size={14} color="#009ddc" />
                  <p>Relatório de Desempenho da escola</p>
                </div>
                <div className="item3-low">
                  <small>
                    Comparação com a média das escolas no concurso e performance
                    média dos alunos por nível de prova, dificuldade e eixos.
                  </small>
                </div>
              </div>
              <div className="padrao-item3">
                <div className="item3-top">
                  <PiCheckFatFill size={14} color="#009ddc" />
                  <p>Valor único para escolas</p>
                </div>
                <div className="item3-low">
                  <small>
                    As escolas têm a liberdade de cadastrar quantos alunos
                    desejarem para participar do Concurso Canguru.
                  </small>
                </div>
              </div>
              <div className="padrao-item3">
                <div className="item3-top">
                  <PiCheckFatFill size={14} color="#009ddc" />
                  <p>As medalhas são itens opcionais</p>
                </div>
                <div className="item3-low">
                  <small>
                    A escola pode optar por adquiri-las ou não. A aquisição está
                    restrita à escola.
                  </small>
                </div>
              </div>
            </div>
            <div className="padrao-low-txt">
              <div className="valor-padrao-solo">
                {activeTab === "Escolas Públicas" && (
                  <div className="destaque-valor-padrao-solo">
                    <p>Valor</p>
                    <p>Em Breve</p>
                  </div>
                )}
                {activeTab === "Escolas Privadas" && (
                  <div className="destaque-valor-padrao-solo">
                    <p>Valor</p>
                    <p>Em Breve</p>
                  </div>
                )}
              </div>
            </div>
            <div className="btn btn-card-padrao">
              <p>Em Breve</p>
            </div>
          </div>
        </div>

        <div className="card-personalizado">
          <div className="semi-circle-personalizado"></div>
          <div className="card-personalizado-content">
            <div className="personalizado-top-txt">
              <h4>
                Concurso <font color="#231f20">Canguru</font>
              </h4>
              <small>Personalizado</small>
            </div>
            <div className="personalizado-mid-txt">
              <div className="personalizado-item0">
                <div className="item0-txt">
                  <PiCheckFatFill size={14} color="#49A942" />
                  <p>
                    Todos os benefícios do
                    <br />
                    Concurso Canguru Padrão
                  </p>
                </div>
                <div className="item0-img">
                  <img src={LINHAPERSONALIZADO} alt="" id="linha" />
                </div>
              </div>
              <div className="personalizado-item1">
                <PiCheckFatFill size={14} color="#49A942" />
                <p>1 Placa de participação</p>
              </div>
              <div className="personalizado-item2">
                <PiCheckFatFill size={14} color="#49A942" />
                <p>
                  1 Revista Canguru do ano: questões
                  <br />
                  de prova + resoluções comentadas
                </p>
              </div>
              <div className="personalizado-item3">
                <div className="item3-personalizado-top">
                  <PiCheckFatFill size={14} color="#49A942" />
                  <p>
                    Relatório de Desempenho
                    <br />
                    dos estudantes
                  </p>
                </div>
              </div>
              <div className="padrao-item3">
                <div className="item3-top">
                  <PiCheckFatFill size={14} color="#49A942" />
                  <p>Valor único para escolas</p>
                </div>
                <div className="item3-low">
                  <small>
                    As escolas têm a liberdade de cadastrar quantos alunos
                    desejarem para participar do Concurso Canguru.
                  </small>
                </div>
              </div>
              <div className="padrao-item3">
                <div className="item3-top">
                  <PiCheckFatFill size={14} color="#49A942" />
                  <p>As medalhas são itens opcionais</p>
                </div>
                <div className="item3-low">
                  <small>
                    A escola pode optar por adquiri-las ou não. A aquisição está
                    restrita à escola.
                  </small>
                </div>
              </div>
            </div>
            <div className="personalizado-low-txt">
              <div className="valor-personalizado-solo">
                {activeTab === "Escolas Públicas" && (
                  <div className="destaque-valor-personalizado-solo">
                    <p>Valor</p>
                    <p>Em Breve</p>
                  </div>
                )}
                {activeTab === "Escolas Privadas" && (
                  <div className="destaque-valor-personalizado-solo">
                    <p>Valor</p>
                    <p>Em Breve</p>
                  </div>
                )}
              </div>
            </div>
            <div className="btn btn-card-personalizado">
              <p>Em Breve</p>
            </div>
          </div>
        </div>

        <div className="separador">
          <p>+</p>
        </div>

        <div className="card-combo">
          <div className="semi-circle-combo"></div>
          <div className="card-combo-content">
            <div className="combo-top-txt">
              <h4>
                <font color="#231f20">Concurso +</font> Experience
              </h4>
            </div>
            <div className="combo-mid-txt">
              <div className="combo-item0">
                <div className="item0-txt">
                  <p>
                    O Concurso Canguru o ano
                    <br />
                    todo na sua escola.
                  </p>
                </div>
              </div>
              <div className="combo-item1">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>
                  Trilha de preparação
                  <br />
                  para o Concurso
                </p>
              </div>
              <div className="combo-item2">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>Atividades de Sondagem</p>
              </div>
              <div className="combo-item3">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>
                  Aula Canguru: roteiros
                  <br />
                  de aulas exclusivos
                </p>
              </div>
              <div className="combo-item4">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>
                  Acervo Canguru: Conteúdo
                  <br />
                  completo do Concurso
                </p>
              </div>
              <div className="combo-item5">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>
                  Formação continuada sobre
                  <br />
                  temas da matemática para
                  <br />
                  professores
                </p>
              </div>
              <div className="combo-item6">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>Acesso para até 10 professores</p>
              </div>
            </div>
            <div className="combo-low-txt">
              {activeTab === "Escolas Públicas" && (
                <div className="combo-valor-padrao">
                  <p>Acrescente o Canguru EXP ao seu pacote</p>
                  <div className="destaque-valor-padrao">
                    <p>Valor</p>
                    <p>Em Breve</p>
                  </div>
                  <div className="boleto-valor-padrao">
                    {/* <p id="left-txt-combo">
                      Parcele em até
                      <br />
                      3x sem juros
                    </p> */}
                    {/* <p id="valor-riscado">R$600,00</p> */}
                  </div>
                </div>
              )}
              {activeTab === "Escolas Privadas" && (
                <div className="combo-valor-padrao">
                  <p>Acrescente o Canguru EXP ao seu pacote</p>
                  <div className="destaque-valor-padrao">
                    <p>Valor</p>
                    <p>Em Breve</p>
                  </div>
                  <div className="boleto-valor-padrao">
                    {/* <p id="left-txt-combo">
                      Parcele em até
                      <br />
                      3x sem juros
                    </p> */}
                    {/* <p id="valor-riscado">R$1200,00</p> */}
                  </div>
                </div>
              )}
            </div>
            {/* <div className="combo-desconto-destaque">
              <p>
                <font size="5">*40%</font> de desconto para novas escolas que se
                inscreverem no Concurso Canguru.
                <br />
                Oferta por tempo limitado!
              </p>
            </div>
            <div className="combo-desconto-low">
              <p>
                *Para as escolas que já fazem parte do Canguru Experience desde
                2023, a renovação está disponível com um desconto garantido de
                50%.
              </p>
            </div> */}
            <div className="btn btn-card-combo">
              <a
                href="https://experience.cangurudematematicabrasil.com.br"
                target="blank"
              >
                Saiba mais
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Inscricoes = () => {
  const [activeTab, setActiveTab] = useState("Escolas Públicas");

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  return (
    <section id="inscricoes">
      <div className="container inscricoes-container">
        <div className="inscricoes-bg">
          <img src={BG} alt="" />
        </div>
        <div className="inscricoes-header">
          <h4>Inscrições</h4>
        </div>

        <div className="tabs">
          <Tab
            label="Escolas Públicas"
            activeTab={activeTab}
            onClick={handleTabClick}
            id="publicas"
          />
          <Tab
            label="Escolas Privadas"
            activeTab={activeTab}
            onClick={handleTabClick}
            id="privadas"
          />
        </div>
        <TabContent activeTab={activeTab} />
      </div>
    </section>
  );
};

export default Inscricoes;
