import React from "react";
import RegOnline from "../../components/regulamentos/online/RegOnline";

const RegulamentoOnline = () => {
  return (
    <>
      <div className="regulamento-nav">
        <div className="regulamento-nav-title">
          <p>Concurso Canguru</p>
          <h5>Regulamento Online</h5>
        </div>
        <div className="regulamento-nav-links">
          <a href="#participacao">Participação</a>
          <a href="#objetivos">Objetivos</a>
          <a href="#inscricao">Inscrição</a>
          <a href="#cadastramento">Cadastramento</a>
          <a href="#provas">Provas</a>
          <a href="#aplicacao-prova">Aplicação da prova</a>
          <a href="#envio-respostas">Envio das respostas</a>
          <a href="#resultados">Resultados</a>
          <a href="#medalhas">Medalhas</a>
          <a href="#regras">Regras</a>
          <a href="#direitos-autorais">Direitos autorais</a>
        </div>
      </div>
      <div className="container regulamento-container" id="regulamento-home">
        <div className="regulamento-header">
          <p>
            <a href="/">Home</a> <font color="#141414">/</font> <a href="/regulamento">Regulamentos</a>{" "}
            <font color="#141414">/</font> Regulamento Online
          </p>
        </div>

        <RegOnline />
      </div>
    </>
  );
};

export default RegulamentoOnline;
