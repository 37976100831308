import React from "react";
import Banner from "../../components/banner/Banner";
import Sobre from "../../components/sobre/Sobre";
import Infos from "../../components/infos/Infos";
import Inscricoes from "../../components/inscricoes/Inscricoes";
import Guia from "../../components/guia/Guia";
import Comunidade from "../../components/comunidade/Comunidade";
import Depoimentos from "../../components/depoimentos/Depoimentos";
import Duvidas from "../../components/duvidas/Duvidas";

const Home = () => {
  return (
    <>
      <Banner />
      <Sobre />
      <Infos />
      <Inscricoes />
      <Guia />
      <Comunidade />
      <Depoimentos />
      <Duvidas />
    </>
  );
};

export default Home;
