import React from "react";
import "./regulamentos.css";

const Regulamentos = () => {
  return (
    <div className="container regulamento-container" id="regulamento-home">
      <div className="regulamento-header">
        <p>
          <a href="/">Home</a> <font color="#141414">/</font> Regulamentos
        </p>
      </div>
      <div className="regulamento-content">
        <div className="regulamento-title">
          <p>Concurso Canguru</p>
          <h5>Regulamentos</h5>
        </div>
        <div className="regulamento-txt">
          <p>
            Os regulamentos do Concurso Canguru estão disponíveis para leitura em duas modalidades: presencial e online.
            A leitura cuidadosa desses documentos é fundamental para compreender as regras, critérios de avaliação e
            demais detalhes pertinentes ao nosso Concurso!
          </p>
        </div>
        <div className="regulamento-buttons">
          <div className="btn btn-online">
            <a href="/regulamento-online">Online</a>
          </div>
          <div className="btn btn-presencial">
            <a href="/regulamento-presencial">Presencial</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Regulamentos;
